body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-steps-item-process {
  font-size: 10px; /* Adjust the font size */
}




.custom-radio .adm-radio-icon {
  width: 24px; /* Adjust for outer circle size */
  height: 24px;
  border: 2px solid #8ced8c; /* Light green border for outer circle */
}

.custom-radio .adm-radio-icon::after {
  width: 12px; /* Inner circle size */
  height: 12px;
  background-color: #8ced8c; /* Inner circle color */
  border-radius: 50%;
}
.ant-radio-checked .ant-radio-inner{
  border-color: #9fe870 !important;
  background-color:transparent  !important;

}

.ant-radio-checked .ant-radio-inner:after{
  background-color:#9fe870 !important;
  color:#9fe870  !important;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #9fe870 !important;

}
.ant-radio-checked .ant-radio-inner:focus{
  border-color:#9fe870 !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.css-dev-only-do-not-override-1wwf28x.ant-steps.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-inline-start: 0;
}

.css-1wwf28x.ant-steps.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-inline-start: 0;
}

.css-dev-only-do-not-override-1wwf28x.ant-steps.ant-steps-small .ant-steps-item-title {
  font-size: 9px !important;
  padding-right: 5px !important;
  user-select: none;
}

.css-1wwf28x.ant-steps.ant-steps-small .ant-steps-item-title {
  font-size: 9px !important;
  padding-right: 5px !important;
  user-select: none;
}

/* step in progress */
.css-dev-only-do-not-override-1wwf28x.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #9FE870 !important;
  border-color: #9FE870 !important;
}

.css-1wwf28x .ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #9FE870 !important;
  border-color: #9FE870 !important;
}

/* complete step */
.css-dev-only-do-not-override-1wwf28x.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #163300 !important;
  border-color: #163300 !important;
}

.css-1wwf28x.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #163300 !important;
  border-color: #163300 !important;
}

/* complete step check  */
.css-dev-only-do-not-override-1wwf28x.ant-steps .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
  color: #9FE870 !important;
}

.css-1wwf28x.ant-steps .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
  color: #9FE870 !important;
}

/* step color */
.css-dev-only-do-not-override-1wwf28x.ant-steps .ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon {
  color: #163300 !important;
}

.css-1wwf28x.ant-steps .ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon {
  color: #163300 !important;
}

/* step line color */
.css-dev-only-do-not-override-1wwf28x.ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background-color: #65686C !important;
}

.css-1wwf28x.ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background-color: #65686C !important;
}

.css-dev-only-do-not-override-1wwf28x.ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background-color: #65686C !important;
}

.css-1wwf28x.ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background-color: #65686C !important;
}

.css-dev-only-do-not-override-1wwf28x.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background-color: #163300 !important;
}

.css-1wwf28x.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background-color: #163300 !important;
}

.custom-input-no-outline:focus {
  box-shadow: none !important;
  outline: none !important;
}

.css-dev-only-do-not-override-1wwf28x.ant-steps.ant-steps-small .ant-steps-item-icon {
  height: 18px !important;
  width: 18px !important;
  font-size: 9px !important;
  line-height: 19px !important;
  margin-right: 5px !important;
  user-select: none;
  /* margin-top: 2px !important; */
}

.css-1wwf28x.ant-steps.ant-steps-small .ant-steps-item-icon {
  height: 18px !important;
  width: 18px !important;
  font-size: 9px !important;
  line-height: 19px !important;
  margin-right: 5px !important;
  user-select: none;
  /* margin-top: 2px !important; */
}

.css-dev-only-do-not-override-1wwf28x.ant-steps .ant-steps-item-container {
  /* margin-top: 2px !important; */
  display: flex !important;
  align-items: center;
}

.css-1wwf28x.ant-steps .ant-steps-item-container {
  /* margin-top: 2px !important; */
  display: flex !important;
  align-items: center;
}

.custom-input .adm-input-element {
  font-size: 12px !important;
  /* Increase font size and ensure it overrides default styles */
}

.custom-input-multiline .adm-text-area-element {
  font-size: 12px !important;
  /* Increase font size and ensure it overrides default styles */
}

.adm-space-horizontal>.adm-space-item {
  margin-right: 0 !important;
}

.custom-button .adm-space-horizontal>.adm-space-item {
  margin-right: 0 !important;
  /* Increase font size and ensure it overrides default styles */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}